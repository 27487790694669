import React from "react";
import ReactDOM from "react-dom/client";
import "./stylesheet/index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "../src/fontawesome-free-5.15.4-web/css/all.css";
import "../src/fontawesome-free-5.15.4-web/js/all.js";
// import { BrowserRouter as Router } from "react-router-dom";
<script src="fontawesome-free-5.15.4-web/js/all.min.js"></script>;
// const t0 = performance.now()
// function renderCallback(e) {
//   console.log("Rendering done?",e);
//   const t1 = performance.now()
//   console.log(`Mount took ~${(t1 - t0) / 1000} seconds.`);
// }
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    {/* <Router> */}
    <App />
    {/* </Router> */}
  </React.StrictMode>
);
