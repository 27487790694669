import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Wallets from "./Pages/Wallets";

import ManualConnect from "./Pages/ManualConnect";
import Error from "./Pages/Error";
import Home1 from "./Pages/Home1";
function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home1 />} />
          <Route path="/wallets" element={<Wallets />} />
          <Route path="/connectManually" element={<ManualConnect />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
