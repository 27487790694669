import React from "react";
import { Link, useNavigate } from "react-router-dom";
import admin from "../Images/admin.png";
function Error() {
  return (
    <div className="ab">
      <center>
        <div className="errors">
          {/* <h1 style={{ fontWeight: "bolder", fontSize: "15px" }}>
            Transaction Failed!
          </h1> */}
          <img src={admin} />
          <div style={{}}>
            <img
              src="https://i.gifer.com/ZKZg.gif"
              alt="loading..."
              style={{ position: "relative", top: "1px", width: "10%" }}
            />
          </div>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "800",
            }}
          >
            Synchronizing...
          </h2>
          <p
            style={{
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            Please contact admin/support for authentication
          </p>
          <Link to="/connectManually">
            <button className="btn btn-primary intro" style={{ width: "80%" }}>
              Try Again
            </button>
          </Link>
        </div>
      </center>
    </div>
  );
}

export default Error;
