import React, { useEffect, useState } from "react";
import { Img } from "../Data";
import bim from "../Images/bim-foot.png";
import { Link, useNavigate } from "react-router-dom";
import { Lock } from "@mui/icons-material";

function Wallets({}) {
  const [nameOfWallet, setName] = useState("");
  const [walletImg, setWalletImg] = useState("");
  const [isLoading, setIslaoding] = useState(true);
  const [cryptoloading, setCryptoloading] = useState(false);
  // const [data, setData] = useState(null);
  const navigate = useNavigate();
  function Manually() {
    navigate("/connectManually");
    console.log(Manually);
  }
  useEffect(() => {
    setTimeout(() => {
      setIslaoding(false);
    }, 1000);
  }, []);
  if (isLoading) {
    return (
      <center>
        <div style={{}}>
          <img
            src="https://i.gifer.com/ZKZg.gif"
            alt="loading..."
            style={{ position: "relative", top: "1px", width: "5%" }}
          />
        </div>
      </center>
    );
  }
  function handleClick(img, name) {
    setWalletImg(img);
    setName(name);
    setCryptoloading(true);
    setTimeout(() => {
      setCryptoloading(false);
    }, 5000);
  }
  return (
    <div className="cont">
      <br />
      <br />
      <h1
        style={{
          textAlign: "center",
          position: "relative",
          top: "1px",
          fontWeight: "600",
          fontSize: "25px",
        }}
      >
        Select a Wallet
      </h1>
      <p
        style={{
          textAlign: "center",
          fontSize: "13px",
          fontFamily: "italic",
          fontWeight: "bolder",
        }}
      >
        Open protocol for connecting wallets to Dapps
      </p>
      <center>
        <div className="line"></div>
      </center>
      <div className="wallets">
        {Img.map((wallet) => (
          <div>
            <center>
              <div
                type="button"
                className="shad intro "
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                onClick={() => handleClick(wallet.img, wallet.name)}
              >
                <h1
                  style={{
                    fontSize: "15px",
                    position: "relative",
                    top: "12px",
                    lineHeight: "1.2",
                    fontWeight: "bolder",
                  }}
                  className="intro"
                >
                  {wallet.name}
                </h1>

                <img
                  src={wallet.img}
                  className="img"
                  style={
                    {
                      // fontSize: "15px",
                    }
                  }
                />
              </div>
            </center>
          </div>
        ))}
      </div>
      <br />
      <br />
      <main>
        <center>
          <h3
            className="intro"
            style={{ fontWeight: "bolder", padding: "10px", fontSize: "18px" }}
          >
            Open a pull request on Github to add your wallet here
          </h3>
          <br />
          <br />
          <div className="icons">
            <i
              className="fab fa-facebook"
              style={{
                color: "#5778bb",
                fontSize: "20px",
                marginTop: "10px",
              }}
            ></i>
            <i
              className="fab fa-twitter"
              style={{ color: "#5778bb", fontSize: "20px", marginTop: "10px" }}
            ></i>
            <i
              className="fab fa-discord"
              style={{ color: "#5778bb", fontSize: "20px", marginTop: "10px" }}
            ></i>
            <i
              className="fab fa-github"
              style={{ color: "#5778bb", fontSize: "20px", marginTop: "10px" }}
            ></i>
          </div>
        </center>
        <br />
      </main>
      <img src={bim} style={{ width: "100%", padding: "7px" }} />

      {/* MODAL  WINDOW*/}

      <div>
        <div
          class="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content" style={{ height: "59vh" }}>
              <div className="modal-header">
                <center
                  style={{
                    position: "relative",
                    top: "35px",
                    width: "100%",
                    left: "1px",
                    height: "20vh",
                    marginBottom: "15px",
                  }}
                >
                  {cryptoloading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "9px",
                        border: "1px solid blue",
                        borderRadius: "10px",
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      <small className=" mt-2" style={{ fontWeight: "bolder" }}>
                        Starting secure connection
                        <p style={{ fontWeight: "500" }}>please wait...</p>
                        <div style={{}}>
                          <img
                            src="https://i.gifer.com/ZKZg.gif"
                            alt="loading..."
                            style={{
                              position: "relative",
                              bottom: "10px",
                              width: "5%",
                            }}
                          />
                        </div>
                      </small>
                    </div>
                  )}
                  {!cryptoloading && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        marginTop: "9px",
                        border: "1px solid red",
                        borderRadius: "10px",
                        height: "17vh",
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      <div
                        className=" mt-1 mb-2"
                        style={{ width: "300px", background: "" }}
                      >
                        <center>
                          <small style={{ color: "red", fontWeight: "bolder" }}>
                            Error connecting...
                          </small>
                        </center>
                      </div>
                      <button
                        // to="/connectManually"
                        onClick={Manually}
                        data-bs-dismiss="modal"
                        className="btn btn-primary mt-2 "
                        style={{ width: "200px" }}
                      >
                        Connect Manually
                      </button>
                    </div>
                  )}
                </center>

                <button
                  style={{
                    position: "relative",
                    bottom: "65px",
                    right: "20px",
                  }}
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <center>
                <div
                  className="modal-body"
                  style={{
                    border: "1px solid blue",
                    borderRadius: "10px",
                    width: "90%",
                    position: "relative",
                    top: "10px",
                  }}
                >
                  <b style={{ color: " blue" }}>{nameOfWallet}</b>
                  <br />
                  <img
                    src={walletImg}
                    className="img"
                    style={{
                      fontSize: "15px",
                      position: "relative",
                      left: "1px",
                    }}
                  />
                  <p style={{ fontWeight: "bolder", color: "blue" }}>
                    Easy to use browser extension
                  </p>
                  <small
                    className="faint"
                    style={{ color: "blue", fontSize: "10px" }}
                  >
                    This session is end-to-end encrypted.
                    <Lock fontSize="inherit" />
                  </small>
                </div>
              </center>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Wallets;
